$mainColor: #dd1571;
$mainColor-100: #ffa1d7;
$mainColor-400: #a42379;
$mainColor-600: #7e1b5599;
$textColor: black;
$white: white;
$whitesmoke: whitesmoke;
$white-100: #efefef;
$white-700:rgba(255, 255, 255, 0.7);
$white-900: rgba(255, 255, 255, 0.9);
$grey: grey;
$grey-100: #444;
$grey-300: #ffffff4d;
$grey-400: #ffffff66;
$grey-500: #ffffff80;
$grey-900: #616161e6;
$lightgrey: lightgrey;
$alert: #C62828;
$alert-100: #ecb9b9;
$black: #000;
$black-300: #333;
$transparent: #00000000;
$transparent-50: #0000000d;
$transparent-120: #0000001f;
$transparent-140: #00000024;
$transparent-200: #00000033;
$transparent-300: #0000004d;
$transparent-400: #00000066;
$transparent-500: rgba(0, 0, 0, 0.5);
$transparent-600: #00000099;
$transparent-700: rgba(0, 0, 0, 0.7);
$transparent-900: #000000e6;
$green: #2E7D32;
$purple: #7B1FA2;
$blue: #536dfe;
$videoBoxShadow:  0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$publicationStatus2BoxShadow: 0 3px 0 0 rgba(255, 192, 0, 0.25), 0 -3px 0 0 rgba(255, 64, 0, 0.25), 3px 0 0 0 rgba(255, 192, 0, 0.25), -3px 0 0 0 rgba(255, 64, 0, 0.25),
3px -3px 0 0 rgba(221, 21, 113, 0.5), -3px 3px 0 0 rgba(221, 21, 113, 0.5), 3px 3px 0 0 rgba(255, 255, 0, 0.75), -3px -3px 0 0 rgba(221, 21, 113, 0.75) !important;
$attachmentLink: #474747;
$status1BgColor: #ffcc00;
$status2BgColor: #eb7c02;
$status3BgColor: #83bb26;
$status4Color: #a5a5a5;
$status4BgColor: #c0c0c0;
$status5Color: #ECEDDC;
$bso: #00bcd4;
$kdv: #e91e63;
$messageSubtitle: #fd9c28;
$messageParagraph: #777777;
$messageParagraph-150: #77777725;

$primaryTheme: (
    50 : #fbe3ee,
    100 : #f5b9d4,
    200 : #ee8ab8,
    300 : #e75b9c,
    400 : #e23886,
    500 : #dd1571,
    600 : #d91269,
    700 : #d40f5e,
    800 : #cf0c54,
    900 : #c70642,
    A100 : #fff0f4,
    A200 : #ffbdce,
    A400 : #ff8aa8,
    A700 : #ff7095,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$secondaryTheme: (
  50 : #e8eaf6,
  100 : #C5CAE9,
  200 : #9fa8da,
  300 : #7986CB,
  400 : #5c6bc0,
  500 : #3f51b5,
  600 : #3949AB,
  700 : #303F9F,
  800 : #283593,
  900 : #1A237E,
  A100 : #8C9EFF,
  A200 : #536DFE,
  A400 : #3D5AFE,
  A700 : #304FFE,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$warnTheme: (
  50 : #FFEBEE,
  100 : #FFCDD2,
  200 : #EF9A9A,
  300 : #E57373,
  400 : #EF5350,
  500 : #F44336,
  600 : #E53935,
  700 : #D32F2F,
  800 : #C62828,
  900 : #B71C1C,
  A100 : #FF8A80,
  A200 : #FF5252,
  A400 : #FF1744,
  A700 : #D50000,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #ffffff,
  )
);
